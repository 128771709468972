<template>
  <div class="page-user-question">
    <!--<p>用户答疑</p>-->
    <el-form :inline="true" :model="queryParams" class="demo-form-inline">
      <el-form-item label="关键词">
        <el-input v-model="queryParams.searchStr" placeholder="关键词匹配" class="custom-width"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="text" @click="handleOpen">我要提问</el-button>
      </el-form-item>
    </el-form>
    <div class="table-wrap">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column label="序号" type="index" width="120" align="left"></el-table-column>
        <el-table-column prop="content" label="内容" show-overflow-tooltip></el-table-column>
        <el-table-column prop="enable" label="审核状态" width="120" align="center">
          <template slot-scope="scope">
            <el-link :type="scope.row.enable == 'Y' ? 'success' : 'danger'" :underline="false">{{scope.row.enable == 'Y' ? '已审核' : '未审核'}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160" align="center">
          <template slot-scope="scope">{{moment(scope.row.createTime).format("YYYY-MM-DD hh:mm:ss")}}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="row-page">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="pageChange"></el-pagination>
    </div>
    <el-dialog :title="dialog.title" :visible.sync="dialog.open" width="600" center :close-on-click-modal="false"
               :destroy-on-close="true">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <!--<el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="简要标题"></el-input>
        </el-form-item>-->
        <el-form-item label="问题分类" prop="category">
          <el-select v-model="form.category" placeholder="请选择类型" value-key="id">
            <el-option :label="item.name" :value="item" v-for="item in categoryOptions" :key="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="问题描述" prop="content">
          <el-input type="textarea" v-model="form.content" placeholder="请详细描述你的问题" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.open = false">取 消</el-button>
        <el-button type="primary" @click="onSureClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'userQuestion',
  data () {
    this.rules =
      {
        title: [
          { required: true, message: '请填写标题' }
        ],
        category: [
          { required: true, message: '请选择问题类型', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请描述您的疑惑' }
        ]
      }
    return {
      tableData: [],
      total: 0,
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        searchStr: ''
      },
      dialog: {
        title: '我要提问',
        open: false
      },
      loading: true,
      form: {},
      categoryOptions: [] // 问题类型
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  methods: {
    search (value) {
      this.queryParams.pageNum = 1
      this.getList()
    },
    pageChange (pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    },
    onSureClick () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { category, ...params } = this.form
          const cateId = category.id
          const cateCode = category.code
          const cateName = category.name
          this.API.questionPush({ ...params, cateId, cateCode, cateName }).then(({ code, data }) => {
            if (code === 200) {
              this.$message.success('您的问题已提交, 可在个人中心中查看状态')
              this.dialog.open = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getList () {
      this.loading = true
      this.API.listByPage(this.queryParams).then(({ code, data }) => {
        this.tableData = data.list
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    getCategory () {
      this.API.getCategory().then(({ code, data }) => {
        this.categoryOptions = data
      })
    },
    handleOpen () {
      this.dialog.open = true
    }
  },
  created () {
    this.getList()
    this.getCategory()
  }
}
</script>

<style scoped lang="scss">
  .page-user-question{
    .demo-form-inline{
      margin: 10px 10px 0;
      .custom-width{
        width: 380px;
      }
    }
    .table-wrap {
      margin: 10px;
    }
  }

</style>
